import React from "react";
import { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import img from "../images/lyric.svg";
import { NavLink } from "react-router-dom";

function Navbar() {
  //
  //Dark mode button
  const [theme, setTheme] = useState(null);
  useEffect(() => {
    if (window.matchMedia("(perfers-color-scheme:dark)").matches) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  }, []);
  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);
  const handleThemeSwitch = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  return (
    <nav className="bg-gray-200 dark:bg-gray-800 px-2 sm:px-4 py-2.5 border-gray-200 ">
      <div className="container flex flex-wrap items-center justify-between mx-auto">
        <NavLink to="/">
          <p className="text-gray-800 dark:text-blue-300 text-4xl grid sm:flex py-4 pl-5">
            LyriqAl <img src={img} alt="Rhythm Icon" className="w-10 ml-3" />
            <span className="text-sm pl-2 font-thin italic">v. 1.0.2</span>
          </p>
        </NavLink>
        <div className="flex flex-row justify-between ">
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  className=" dark:bg-white bg-gray-900 drop-shadow-md rounded cursor-pointer block py-2 pl-3 pr-1"
                  onClick={handleThemeSwitch}
                />
              }
            />
          </FormGroup>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
