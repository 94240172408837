import React from "react";
import logo from "../images/logo.png";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <footer className=" text-gray-800 dark:text-white md:flex md:items-center relative md:justify-between md:p-6 sm:p-8 bg-gray-200 dark:bg-gray-800">
      <a
        href="https://twitter.com/ohitspm/"
        className="flex items-center w-auto mb-4 sm:mb-0 sm:pb-4"
        target="_blank"
        rel="noreferrer"
      >
        <img src={logo} className="h-8 mr-3" alt="Peter Logo" />
      </a>
      <ul className="flex flex-wrap items-center mt-3 sm:mt-0">
        <li>
          <NavLink to="About">
            <p className=" mr-4 hover:underline md:mr-6 dark:text-white">
              Docs
            </p>
          </NavLink>
        </li>
        <li>
          <NavLink to="ContactForm">
            <p className=" mr-4 hover:underline md:mr-6 dark:text-white">
              Contact
            </p>
          </NavLink>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
