import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { lazy, React, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Loading from "./pages/Loading";
import { AuthContextProvider } from "./services/AuthContext";

// import Protected from "./components/Protected";

const Main = lazy(() => import("./pages/Main"));
const Error = lazy(() => import("./pages/Error"));
const About = lazy(() => import("./pages/About"));
const ContactForm = lazy(() => import("./pages/ContactForm"));
const Legal = lazy(() => import("./pages/Legal"));
const Guidelines = lazy(() => import("./pages/Guidelines"));

// const SignIn = lazy(() => import("./pages/SignIn"));

function App() {
  return (
    <>
      <AuthContextProvider>
        <Navbar />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/About" element={<About />} />
            <Route path="/" element={<Main />} />
            <Route path="*" element={<Error />} />
            <Route path="/ContactForm" element={<ContactForm />} />
            <Route path="/Legal" element={<Legal />} />
            <Route path="/Guidelines" element={<Guidelines />} />
          </Routes>
        </Suspense>
        <Footer />
      </AuthContextProvider>
    </>
  );
}

export default App;
